.display-p {
  @extend p;
  @include font-size(1.25rem);
}

p {
  font-family: $font-family-monospace;
}
.p {
  @extend p;
}


.form-label,
.form-value {
  font-family: $font-family-monospace;
  margin-bottom: .25rem;
  font-size: .875rem;
  line-height: 1.4;
  display: block;
}

tr:last-child {
  .form-label,
  .form-value {
    margin-bottom: 0;
  }
}

.form-label {
  font-weight: bold;
  color: $gray-800;
}
.form-label.href {
  text-decoration: underline;
  color: $gray-800;
  &:hover {
    color: $gray-600;
  }
  &.active {
    color: $gray-600;
    text-decoration: none;
  }
}

a.form-label {
  @extend a;
}

small, .small {
  font-size: .75rem;
}

.uppercase-label {
  font-size: 1rem;
  text-transform: uppercase;
  font-family: $font-family-monospace;
  font-weight: bold;
  color: $gray-600;
  margin-bottom: $spacer / 2;
}

.underlined {
  position: relative;
  margin-bottom: $spacer / 2;
  padding-bottom: $spacer / 2;

  .uppercase-label {
    margin: 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-bottom: 1px solid #aaa;
    box-shadow: 0 0 1px #aaa;
  }
}



//@include media-breakpoint-up(md) {
//  #{$selector} {
//    font-size: $font-size * (1 - (0.1 * $multiplier));
//  }
//}
//@include media-breakpoint-up(lg) {
//  #{$selector} {
//    font-size: $font-size;
//  }
//}
//@include responsive-headings('display-2', $h1-font-size, -4);
//@include responsive-headings('h1', $h1-font-size, .6);
//@include responsive-headings('h2', $h2-font-size, 1);
//@include responsive-headings('h3', $h3-font-size, 1);
//@include responsive-headings('h4', $h4-font-size, 0.7);
//@include responsive-headings('h5', $h5-font-size, 0.6);
//@include responsive-headings('h6', $h6-font-size, 0.5);
