.ratio-box {
  position: relative;
  height: 0;
  display: block;
  width: 100%;
  padding-bottom: 100%;
  /* padding-bottom is calculated and rendered in to HTML */
}

.ratio-box img,
.ratio-box iframe,
.ratio-box video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
