.fadeIn {
  animation: fadeIn 500ms ease-in-out; /* Modern browsers */
}

/* Modern browsers */
@keyframes fadeIn { from { opacity: 0; } to { opacity: 1; } }

.fade-down-enter {
  opacity: 0.01;
  transform: translateY(-100%) scale(0.75);
}
.fade-down-enter-active {
  opacity: 1;
  transform: translateY(0%) scale(1.25);
  transition: all 300ms ease-out;
}
.fade-down-exit {
  opacity: 1;
  transform: scale(1.25);
}
.fade-down-exit-active {
  opacity: 0;
  transform: scale(4);
  transition: all 300ms ease-in;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.fadeInFromRight {

  opacity: 0;
  animation: fadeInFromRight .5s ease forwards;
  animation-delay: 0s;

  &.second {
    animation-delay: .2s;
  }
  &.third {
    animation-delay: .4s;
  }
  &.fourth {
    animation-delay: .50s;
  }

}
@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    transform: translateX(5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
