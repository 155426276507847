.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  border-radius: 4px;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-resizable-handle {

  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  cursor: se-resize;
  width: 24px;
  height: 24px;
  animation: move 1s;

  &:before {
    content: '';
    position: absolute;
    right: 3px;
    bottom: 3px;

    border-bottom: 2px solid white;
    border-right: 2px solid white;
    width: 12px;
    height: 12px;
    border-bottom-right-radius: 3px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
  }

}

@keyframes move {
  0% {
    opacity: 0
  }

  /* Finish changes by here */
  20% {
    opacity: 0;
  }

  /* Between 20% and 100%, nothing changes */
  100% {
    opacity: 1;
  }
}

.lightHandle .react-resizable-handle:before,
.lightHandle .react-resizable-handle:after {
  border-color: #fff;
}

.darkHandle + .react-resizable-handle:before,
.darkHandle + .react-resizable-handle:after {
  border-color: #555;
}
