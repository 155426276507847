
.serif {
  font-family: $font-family-serif;
}

.sans-serif {
  font-family: $font-family-sans-serif;
}

.mono {
  font-family: $font-family-monospace;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a.disabled {
  pointer-events: none;
}

.href {
  cursor: pointer;
  color: $link-color;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

.fill {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.flex-grow {
  flex-grow: 1;
}

.pointer-events-none {
  pointer-events: none;
}

// ct = clear top
.ct,
.cy {
  padding-top: 80px;

  @include media-breakpoint-up(sm) {
    padding-top: 100px;
  }
  @include media-breakpoint-up(md) {
    padding-top: 100px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 120px;
  }
}

.cb,
.cy {
  padding-bottom: 80px;
}

.container,
.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}

.text-underline {
  text-decoration: underline;
}

.container-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
