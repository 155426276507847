@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&family=Poppins:ital,wght@0,400;1,600&display=swap');

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Local variables (not Bootstrap)
$font-family-serif: 'Poppins';

// Colors
$type-black: #09090C;
$type-white: #FFF;
$type-red: #D73A49;
$type-gray: #6A737D;
$link-blue: #0047B1;
$buttons-primary: #0D9669;
$buttons-default: #F6F8FA;
$buttons-navy: #31456A;
$border-blue: #0366D6;
$gray-000: #F6F8FA;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// 👇 This is all important!!
$spacer: 1.37rem;

$paragraph-margin-bottom: $spacer;
$headings-margin-bottom: $spacer/2;
$headings-line-height: 1.1;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: "IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-monospace;
$font-size: 1rem !default;
$font-size-lg: 2.125rem !default;
$font-size-sm: .80rem !default;
$headings-font-family: $font-family-serif;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base *1.5;
$h4-font-size: $font-size-base * 1.125;
$display1-size: 5.625rem;
$display2-size: 3.75rem;
$lead-font-size: 1.975rem;
$lead-font-weight: 400;
@import "./bootstrap-overrides/type";

$btn-padding-y-lg: 12px;
$link-color: $link-blue;
@import "./bootstrap-overrides/buttons";

$text-colors: (
        "gray": $gray-500,
        "dark": $type-black,
        "red": $type-red,
        "white": $type-white,
);
@import "./bootstrap-overrides/text";

$alert-padding-y: $spacer;
$alert-padding-x: $spacer;
@import "./bootstrap-overrides/alert";

$border-width: 2px;
$border-color: $gray-500;
$border-colors: (
        "gray": $gray-500,
        "dark": $type-black,
);
@import "./bootstrap-overrides/borders";

$background-colors: (
        "red": $gray-200,
        "gray": $gray-400,
        "dark": $type-black,
);
@import "./bootstrap-overrides/background";

$nav-tabs-link-hover-border-color: $gray-800;
$nav-tabs-border-color: $gray-200;
$nav-tabs-link-active-border-color: $border-blue;
@import "./bootstrap-overrides/nav";

$list-group-item-padding-y: $spacer;
$list-group-item-padding-x: $spacer;
@import "./bootstrap-overrides/list-group";

@import "./bootstrap-overrides/sizing";
@import "./bootstrap-overrides/stretched-link";

// Bootstrap
$paragraph-margin-bottom: $spacer;
$label-margin-bottom: $spacer/4;
@import "~bootstrap/scss/reboot";

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 769px, // Because an iPad in portrait is 768 across
        lg: 992px,
        xl: 1200px
);
@import "~bootstrap/scss/grid";

$form-group-margin-bottom: $spacer;
$form-feedback-font-size: .8rem;
$input-bg: $gray-000;
$input-focus-bg: $gray-000;
$input-height-lg: calc(3.375rem + 2px);
$input-padding-y: 0.9375rem;
$input-padding-x: 0.9375rem;
$input-padding-x-lg: 1.4rem;
@import "~bootstrap/scss/forms";

$custom-select-height-lg: $input-height-lg;
@import "~bootstrap/scss/custom-forms";

$spacers: map-merge($spacers, (
        qtr: .25rem,
        half: .5rem,
        1rem: 1rem,
        2rem: 2rem,
        3rem: 3rem,
        4rem: 4rem,
        6rem: 6rem,
));
@import "~bootstrap/scss/utilities/spacing";

@import "~bootstrap/scss/utilities/screenreaders";
@import "~bootstrap/scss/utilities/overflow";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/utilities/position";
@import "~bootstrap/scss/utilities/float";
@import "~bootstrap/scss/utilities/clearfix";
@import "~bootstrap/scss/utilities/shadows";
@import "~bootstrap/scss/utilities/align";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/text";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group"; // Used in




@import 'app/type'; // Our own type styles
@import "app/navbar";
@import "app/tables";
@import 'app/animations';
@import "app/nprogress";
@import "app/icons";
@import "app/ratio-box";
@import "app/react-grid-layout";
@import "helpers";


html {
  font-size: 16px;
  @media (max-width: map-get($grid-breakpoints, 'sm') - .02) {
    font-size: 14px;
  }
  min-width: 320px;
}

body {
  line-height: 1.4; // Overriding bootstrap _reset.scss
}

input:-webkit-autofill {
  font: $font-size-base;
}

ol,
ul {
  padding-left: 16px;
}

.bottom-action-bar {
  position: fixed;
  z-index: 100;
  background: rgb(255, 255, 255);
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgb(204, 204, 204);
  border-bottom: 1px solid rgb(204, 204, 204);
}
