
/**
  For icons aligned to text
  https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
 */
.icon {
  display: inline-flex;
  align-self: center;
}

.icon.baseline svg, .icon img {
  top: .125em;
  position: relative;
}

